import React, { Component } from 'react';
//import 'antd/dist/antd.css';
import { message } from 'antd';
import * as actioncreators from '../../redux/action';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

class SignOut extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let userData = new FormData();
    userData.append('userId', this.props.userdetails.id);
    // userData.append("userid", '1926');
    const config = {
      method: 'POST',
      body: userData,
    };
    fetch(process.env.REACT_APP_apiurl + '/User/sessionout/', config)
      .then((response) =>
        //  //console.log(result))
        response.json()
      )
      .then((response) => {
        // console.log(response);
        if (response.success === 1) {
          message.success(response.result[0].successMsg);
          // Remove the token item from sessionStorage so that user get redirected to login page
          sessionStorage.removeItem('token');
          this.props.actions.resetNotification('true');
        } else {
          message.error('Problem signing out...');
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  }

  render() {
    return <div>{this.props.history.push('/signin')}</div>;
  }
}

const mapStateToProps = (state) => {
  return state;
};

function mapDispatchToProps(dispatch, state) {
  return {
    actions: bindActionCreators(actioncreators, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SignOut)
);
