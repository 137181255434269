import React, { Component } from 'react';
import './SlotManagement.scss';
//import 'antd/dist/antd.css';
import { LeftOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  DatePicker,
  InputNumber,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Table,
  Tabs,
  TimePicker,
  Typography,
} from 'antd';
import { NavLink, withRouter } from 'react-router-dom';
import * as actioncreators from '../../redux/action';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
let moment = require('moment');
const { Option } = Select;
const { Title } = Typography;

class SlotManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      physicianDetails: null,
      slots: [],
      exceptions: [],
      physicianAssociationId: null,
      physicianId: null,
      clinics: [],
      associateNewClinic: false,
      exceptionSlotView: false,
      slotDate: moment().format('YYYY-MM-DD'),
      slotEditControl: '',
      visible: false, // Flag to show/hide modal box
      loading: false, // Flag to show/hide loading icon
      tableColumns: [
        {
          title: 'Monday',
          dataIndex: 'Mon',
          key: 'Mon',
          render: (text) => (
            <div className={'slotBox'}>
              <span className={'slotTime'}>{text[0]}</span>
              {text[0] !== 'NA' ? (
                <span className={'editSlot'}>
                  <Checkbox
                    onClick={(event) => this.deleteSlot(event, text[3], 'Mon')}
                    checked={text[2] === 'Active' ? true : false}
                    value={text[1]}
                  ></Checkbox>
                </span>
              ) : (
                <span className={'addSlot'}>
                  <Button
                    onClick={(event) => this.addNewSlot('Mon', text[3])}
                    type="default"
                    shape="circle"
                  >
                    +
                  </Button>
                </span>
              )}
            </div>
          ),
        },
        {
          title: 'Tuesday',
          dataIndex: 'Tue',
          key: 'Tue',
          render: (text) => (
            <div className={'slotBox'}>
              <span className={'slotTime'}>{text[0]}</span>
              {text[0] !== 'NA' ? (
                <span className={'editSlot'}>
                  <Checkbox
                    onClick={(event) => this.deleteSlot(event, text[3], 'Tue')}
                    checked={text[2] === 'Active' ? true : false}
                    value={text[1]}
                  ></Checkbox>
                </span>
              ) : (
                <span className={'addSlot'}>
                  <Button
                    onClick={(event) => this.addNewSlot('Tue', text[3])}
                    type="default"
                    shape="circle"
                  >
                    +
                  </Button>
                </span>
              )}
            </div>
          ),
        },
        {
          title: 'Wednesday',
          dataIndex: 'Wed',
          key: 'Wed',
          render: (text) => (
            <div className={'slotBox'}>
              <span className={'slotTime'}>{text[0]}</span>
              {text[0] !== 'NA' ? (
                <span className={'editSlot'}>
                  <Checkbox
                    onClick={(event) => this.deleteSlot(event, text[3], 'Wed')}
                    checked={text[2] === 'Active' ? true : false}
                    value={text[1]}
                  ></Checkbox>
                </span>
              ) : (
                <span className={'addSlot'}>
                  <Button
                    onClick={(event) => this.addNewSlot('Wed', text[3])}
                    type="default"
                    shape="circle"
                  >
                    +
                  </Button>
                </span>
              )}
            </div>
          ),
        },
        {
          title: 'Thursday',
          dataIndex: 'Thu',
          key: 'Thu',
          render: (text) => (
            <div className={'slotBox'}>
              <span className={'slotTime'}>{text[0]}</span>
              {text[0] !== 'NA' ? (
                <span className={'editSlot'}>
                  <Checkbox
                    onClick={(event) => this.deleteSlot(event, text[3], 'Thu')}
                    checked={text[2] === 'Active' ? true : false}
                    value={text[1]}
                  ></Checkbox>
                </span>
              ) : (
                <span className={'addSlot'}>
                  <Button
                    onClick={(event) => this.addNewSlot('Thu', text[3])}
                    type="default"
                    shape="circle"
                  >
                    +
                  </Button>
                </span>
              )}
            </div>
          ),
        },
        {
          title: 'Friday',
          dataIndex: 'Fri',
          key: 'Fri',
          render: (text) => (
            <div className={'slotBox'}>
              <span className={'slotTime'}>{text[0]}</span>
              {text[0] !== 'NA' ? (
                <span className={'editSlot'}>
                  <Checkbox
                    onClick={(event) => this.deleteSlot(event, text[3], 'Fri')}
                    checked={text[2] === 'Active' ? true : false}
                    value={text[1]}
                  ></Checkbox>
                </span>
              ) : (
                <span className={'addSlot'}>
                  <Button
                    onClick={(event) => this.addNewSlot('Fri', text[3])}
                    type="default"
                    shape="circle"
                  >
                    +
                  </Button>
                </span>
              )}
            </div>
          ),
          // }
        },
        {
          title: 'Saturday',
          dataIndex: 'Sat',
          key: 'Sat',
          render: (text) => (
            <div className={'slotBox'}>
              <span className={'slotTime'}>{text[0]}</span>
              {text[0] !== 'NA' ? (
                <span className={'editSlot'}>
                  <Checkbox
                    onClick={(event) => this.deleteSlot(event, text[3], 'Sat')}
                    checked={text[2] === 'Active' ? true : false}
                    value={text[1]}
                  ></Checkbox>
                </span>
              ) : (
                <span className={'addSlot'}>
                  <Button
                    onClick={(event) => this.addNewSlot('Sat', text[3])}
                    type="default"
                    shape="circle"
                  >
                    +
                  </Button>
                </span>
              )}
            </div>
          ),
        },
        {
          title: 'Sunday',
          dataIndex: 'Sun',
          key: 'Sun',
          render: (text) => (
            <div className={'slotBox'}>
              <span className={'slotTime'}>{text[0]}</span>
              {text[0] !== 'NA' ? (
                <span className={'editSlot'}>
                  <Checkbox
                    onClick={(event) => this.deleteSlot(event, text[3], 'Sun')}
                    checked={text[2] === 'Active' ? true : false}
                    value={text[1]}
                  ></Checkbox>
                </span>
              ) : (
                <span className={'addSlot'}>
                  <Button
                    onClick={(event) => this.addNewSlot('Sun', text[3])}
                    type="default"
                    shape="circle"
                  >
                    +
                  </Button>
                </span>
              )}
            </div>
          ),
        },
      ],
      slotsMorningDataSource: [],
      slotsBeforeLunchDataSource: [],
      slotsAfterLunchDataSource: [],
      slotsEveningDataSource: [],
      addModal: {
        appttype: '01',
        userid: null,
        duration: 10,
        waitingTime: 5,
        startTime: '00:00',
        endTime: '00:00',
      },
    };
    // this.showModal.bind(this);
  }

  //// to call get leads api /////
  componentDidMount() {
    this.setState({
      addModal: {
        ...this.state.addModal,
        userid: this.props.userdetails.id,
      },
    });
    let userData = new FormData();
    userData.append('userid', this.props.userdetails.id);
    // userData.append("userid", '1926');
    const config = {
      method: 'POST',
      body: userData,
    };
    fetch(
      process.env.REACT_APP_apiurl + '/Physician/getphysicianclinic/',
      config
    )
      .then((result) =>
        //  //console.log(result))
        result.json()
      )
      .then((result) => {
        //console.log(result);
        if (result.success === 1) {
          let physicianDetails = result.result.filter(function (item) {
            if (item.clinicstatus === 'Approved' && item.doctorstatus === 'Y') {
              return {
                clinicId: item.clinicid,
                clinicName: item.clinic,
              };
            }
          });
          let clinics = physicianDetails.map(function (item) {
            return {
              clinicId: item.clinicid,
              clinicName: item.clinic,
              physicianAssociationId: item.id,
            };
          });
          this.setState({
            physicianDetails: physicianDetails[0],
            physicianAssociationId: physicianDetails[0].id,
            clinics: clinics,
          });
          this.getSlots();
        } else {
          message.error('Physician details could not be extracted.');
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleModalCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleModalSubmit = () => {
    this.setState({
      loading: true,
    });
    let slotPeriodStr;
    switch (this.state.addModal.slotPeriod) {
      case 'MS':
        slotPeriodStr = 'Morning';
        break;
      case 'BLS':
        slotPeriodStr = 'Before Lunch';
        break;
      case 'ALS':
        slotPeriodStr = 'After Lunch';
        break;
      case 'ES':
        slotPeriodStr = 'Evening';
        break;
    }
    let slotsData = new FormData();
    slotsData.append('userid', this.state.addModal.userid);
    // slotsData.append("userid", '1926');
    slotsData.append('associationId', this.state.physicianAssociationId);
    // slotsData.append("associationId", '141');
    slotsData.append('day', this.state.addModal.slotDay);
    slotsData.append('period', slotPeriodStr);
    slotsData.append('starttime', this.state.addModal.startTime);
    slotsData.append('endtime', this.state.addModal.endTime);
    slotsData.append('duration', this.state.addModal.duration);
    slotsData.append('waitingtime', this.state.addModal.waitingTime);
    slotsData.append('appttype', this.state.addModal.appttype);
    const config = {
      method: 'POST',
      body: slotsData,
    };
    fetch(process.env.REACT_APP_apiurl + '/Timeslot/addtimeslot/', config)
      .then((result) =>
        //  //console.log(result))
        result.json()
      )
      .then((result) => {
        // console.log(result);
        if (result.success === 1) {
          this.getSlots();
          this.setState({
            loading: false,
          });
          this.handleModalCancel();
        } else {
          message.error('Slots could not added...');
        }
        this.setState({
          loading: false,
        });
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  getSlots = (physicianAssociationId) => {
    // fetch the slots for each day for this physicians
    let associationId =
        physicianAssociationId || this.state.physicianAssociationId,
      days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      slotSections = ['Morning', 'Before Lunch', 'After Lunch', 'Evening'];
    // console.log(associationId);
    let that = this;
    let slots = [];
    days.forEach(function (day) {
      slotSections.forEach(function (slot) {
        let reqObj = new FormData();
        // reqObj.append('associationId', '141');
        reqObj.append('associationId', associationId);
        reqObj.append('slotDay', day);
        reqObj.append('slotPeriod', slot);
        let postConfig = {
          method: 'POST',
          body: reqObj,
        };
        fetch(
          process.env.REACT_APP_apiurl + '/Timeslot/getslotbydayperiod/',
          postConfig
        )
          .then((result) => result.json())
          .then((result) => {
            let slotsLength;
            // console.log(result);
            if (result.success === 1) {
              slotsLength = slots.push({
                key: day + Math.random(),
                slotDay: day,
                slotPeriod: slot,
                slots: result.result,
              });
            } else {
              slotsLength = slots.push({
                key: day + Math.random(),
                slotDay: day,
                slotPeriod: slot,
                slots: [],
              });
            }
            that.updateSlots(slots);
          })
          .catch((err) => {
            //console.log(err);
          });
      });
    });
    // let reqObj = new FormData();
    // reqObj.append('associationId', '141');
    // let postConfig = {
    //   method: 'POST',
    //   body: reqObj
    // };
    // fetch(process.env.REACT_APP_apiurl + "/Timeslot/getslotbydayperiod/", postConfig).then(result =>
    //   result.json()).then(result => {
    //   console.log(result);
    //   if (result.success === 1) {
    //     result.result.map(function(slot) {
    //       slots.push({
    //         key: slot.day_name + Math.random(),
    //         slotDay: slot.day_name,
    //         slotPeriod: slot.period,
    //         slots: slot
    //       });
    //     });
    //   } else {
    //     message.error('Slots could not retrieved...');
    //   }
    //   console.log(slots);
    //   that.updateSlots(slots);
    // }).catch(err => {
    //   //console.log(err);
    // });
  };

  addNewSlot = (slotDay, slotPeriod) => {
    // console.log(slotDay);
    // console.log(slotPeriod);
    this.setState({
      addModal: {
        ...this.state.addModal,
        slotDay,
        slotPeriod,
      },
      addSlots: slotDay === '',
    });
    this.showModal();
  };

  deleteSlot = (event, slotPeriod, slotDay) => {
    let slotId = event.target.value;
    // Code to update the slot status
    let slotData = new FormData();
    slotData.append('slotId', slotId);
    const slotConfig = {
      method: 'POST',
      body: slotData,
    };
    let that = this;
    fetch(
      process.env.REACT_APP_apiurl + '/Timeslot/deletetimeslotbyslotid/',
      slotConfig
    )
      .then((result) => result.json())
      .then((result) => {
        // console.log(result);
        if (result.success === 1) {
          // console.log(result);
          that.getSlots();
          message.success('Slot deleted successfully');
        } else {
          message.error(
            'Problem deleting selected slot, please try again in sometime'
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  updateSlots = (slots) => {
    // console.log(slots);
    let morningSlots = [],
      beforeLunchSlots = [],
      afterLunchSlots = [],
      eveningSlots = [];
    morningSlots = slots.filter((slot) => slot.slotPeriod === 'Morning');
    beforeLunchSlots = slots.filter(
      (slot) => slot.slotPeriod === 'Before Lunch'
    );
    afterLunchSlots = slots.filter((slot) => slot.slotPeriod === 'After Lunch');
    eveningSlots = slots.filter((slot) => slot.slotPeriod === 'Evening');
    // console.log(morningSlots);

    let maxRowsMS = {
        max: 0,
      },
      maxRowsBLS = {
        max: 0,
      },
      maxRowsALS = {
        max: 0,
      },
      maxRowsES = {
        max: 0,
      },
      morningDataSource = [],
      beforeLunchDataSource = [],
      afterLunchDataSource = [],
      eveningDataSource = [];

    // for (let i = 0; i < morningSlots.length; i++) {
    //   let daySlots = {
    //     Fri: ['NA', '', '', 'MS'],
    //     Mon: ['NA', '', '', 'MS'],
    //     Sat: ['NA', '', '', 'MS'],
    //     Sun: ['NA', '', '', 'MS'],
    //     Thu: ['NA', '', '', 'MS'],
    //     Tue: ['NA', '', '', 'MS'],
    //     Wed: ['NA', '', '', 'MS'],
    //     key: 'MS-' + i
    //   };
    //   daySlots[morningSlots[i].day_name] = [
    //     morningSlots[i].slot_time, morningSlots[i].id, 'Active', 'MS'
    //   ];
    //   morningDataSource.push(daySlots);
    // }
    // console.log(morningDataSource);

    morningSlots.forEach(function (slot) {
      if (slot.slots.length > 0 && slot.slots.length > maxRowsMS.max) {
        maxRowsMS.max = slot.slots.length;
      }
    });
    beforeLunchSlots.forEach(function (slot) {
      if (slot.slots.length > 0 && slot.slots.length > maxRowsBLS.max) {
        maxRowsBLS.max = slot.slots.length;
      }
    });
    afterLunchSlots.forEach(function (slot) {
      if (slot.slots.length > 0 && slot.slots.length > maxRowsALS.max) {
        maxRowsALS.max = slot.slots.length;
      }
    });
    eveningSlots.forEach(function (slot) {
      if (slot.slots.length > 0 && slot.slots.length > maxRowsES.max) {
        maxRowsES.max = slot.slots.length;
      }
    });

    if (maxRowsMS.max > 0) {
      for (let i = 0; i < maxRowsMS.max; i++) {
        let daySlots = {
          Fri: ['NA', '', '', 'MS'],
          Mon: ['NA', '', '', 'MS'],
          Sat: ['NA', '', '', 'MS'],
          Sun: ['NA', '', '', 'MS'],
          Thu: ['NA', '', '', 'MS'],
          Tue: ['NA', '', '', 'MS'],
          Wed: ['NA', '', '', 'MS'],
          key: 'MS-' + i,
        };
        let that = this;
        morningSlots.forEach(function (slot) {
          if (slot.slots.length > 0 && slot.slots[i] !== undefined) {
            daySlots[slot.slotDay] = [
              slot.slots[i].slot_time,
              slot.slots[i].id,
              'Active',
              'MS',
            ];
          } else {
            daySlots[slot.slotDay] = ['NA', '', '', 'MS'];
          }
        });
        morningDataSource.push(daySlots);
      }
      // console.log(morningDataSource);
    } else {
      morningDataSource.push({
        Fri: ['NA', '', '', 'MS'],
        Mon: ['NA', '', '', 'MS'],
        Sat: ['NA', '', '', 'MS'],
        Sun: ['NA', '', '', 'MS'],
        Thu: ['NA', '', '', 'MS'],
        Tue: ['NA', '', '', 'MS'],
        Wed: ['NA', '', '', 'MS'],
        key: 'MS-0',
      });
    }

    if (maxRowsBLS.max > 0) {
      for (let i = 0; i < maxRowsBLS.max; i++) {
        let daySlots = {
          Fri: ['NA', '', '', 'BLS'],
          Mon: ['NA', '', '', 'BLS'],
          Sat: ['NA', '', '', 'BLS'],
          Sun: ['NA', '', '', 'BLS'],
          Thu: ['NA', '', '', 'BLS'],
          Tue: ['NA', '', '', 'BLS'],
          Wed: ['NA', '', '', 'BLS'],
          key: 'BLS-' + i,
        };
        beforeLunchSlots.forEach(function (slot) {
          if (slot.slots.length > 0 && slot.slots[i] !== undefined) {
            daySlots[slot.slotDay] = [
              slot.slots[i].slot_time,
              slot.slots[i].id,
              'Active',
              'BLS',
            ];
          } else {
            daySlots[slot.slotDay] = ['NA', '', '', 'BLS'];
          }
        });
        beforeLunchDataSource.push(daySlots);
      }
    } else {
      beforeLunchDataSource.push({
        Fri: ['NA', '', '', 'BLS'],
        Mon: ['NA', '', '', 'BLS'],
        Sat: ['NA', '', '', 'BLS'],
        Sun: ['NA', '', '', 'BLS'],
        Thu: ['NA', '', '', 'BLS'],
        Tue: ['NA', '', '', 'BLS'],
        Wed: ['NA', '', '', 'BLS'],
        key: 'BLS-0',
      });
    }

    if (maxRowsALS.max > 0) {
      for (let i = 0; i < maxRowsALS.max; i++) {
        let daySlots = {
          Fri: ['NA', '', '', 'ALS'],
          Mon: ['NA', '', '', 'ALS'],
          Sat: ['NA', '', '', 'ALS'],
          Sun: ['NA', '', '', 'ALS'],
          Thu: ['NA', '', '', 'ALS'],
          Tue: ['NA', '', '', 'ALS'],
          Wed: ['NA', '', '', 'ALS'],
          key: 'ALS-' + i,
        };
        afterLunchSlots.forEach(function (slot) {
          if (slot.slots.length > 0 && slot.slots[i] !== undefined) {
            daySlots[slot.slotDay] = [
              slot.slots[i].slot_time,
              slot.slots[i].id,
              'Active',
              'ALS',
            ];
          } else {
            daySlots[slot.slotDay] = ['NA', '', '', 'ALS'];
          }
        });
        afterLunchDataSource.push(daySlots);
      }
    } else {
      afterLunchDataSource.push({
        Fri: ['NA', '', '', 'ALS'],
        Mon: ['NA', '', '', 'ALS'],
        Sat: ['NA', '', '', 'ALS'],
        Sun: ['NA', '', '', 'ALS'],
        Thu: ['NA', '', '', 'ALS'],
        Tue: ['NA', '', '', 'ALS'],
        Wed: ['NA', '', '', 'ALS'],
        key: 'ALS-0',
      });
    }

    if (maxRowsES.max > 0) {
      for (let i = 0; i < maxRowsES.max; i++) {
        let daySlots = {
          Fri: ['NA', '', '', 'ES'],
          Mon: ['NA', '', '', 'ES'],
          Sat: ['NA', '', '', 'ES'],
          Sun: ['NA', '', '', 'ES'],
          Thu: ['NA', '', '', 'ES'],
          Tue: ['NA', '', '', 'ES'],
          Wed: ['NA', '', '', 'ES'],
          key: 'ES-' + i,
        };
        eveningSlots.forEach(function (slot) {
          if (slot.slots.length > 0 && slot.slots[i] !== undefined) {
            daySlots[slot.slotDay] = [
              slot.slots[i].slot_time,
              slot.slots[i].id,
              'Active',
              'ES',
            ];
          } else {
            daySlots[slot.slotDay] = ['NA', '', '', 'ES'];
          }
        });
        eveningDataSource.push(daySlots);
      }
    } else {
      eveningDataSource.push({
        Fri: ['NA', '', '', 'ES'],
        Mon: ['NA', '', '', 'ES'],
        Sat: ['NA', '', '', 'ES'],
        Sun: ['NA', '', '', 'ES'],
        Thu: ['NA', '', '', 'ES'],
        Tue: ['NA', '', '', 'ES'],
        Wed: ['NA', '', '', 'ES'],
        key: 'ES-0',
      });
    }

    this.setState({
      slots,
      slotsMorningDataSource: morningDataSource,
      slotsBeforeLunchDataSource: beforeLunchDataSource,
      slotsAfterLunchDataSource: afterLunchDataSource,
      slotsEveningDataSource: eveningDataSource,
    });
  };

  // changeDateSelection = (date, dateString) => {
  //   let revenueCond = "'" + dateString[0] + "' and '" + dateString[1] + "'";
  //   this.setState({
  //     fromDate: dateString[0],
  //     toDate: dateString[1],
  //     rangeValue: date
  //   });
  //   // console.log(revenueCond);
  //   this.props.actions.getPhysicianRevenueDetails(this.state.physicianDetails.id, revenueCond);
  // }
  //
  // disabledDate = (current) => {
  //   // Can not select days after today
  //   return current && current.valueOf() > Date.now();
  // };

  handleAssociateNewClinic = (event) => {
    this.setState({
      associateNewClinic: event.target.value,
    });
  };

  handleSlotViewChange = (event) => {
    this.setState({
      exceptionSlotView: event.target.value,
    });
  };

  handleSlotDateChange = (date, dateString) => {
    this.setState({
      slotDate: dateString,
    });
    // console.log(date, dateString);
  };

  newSlotDayChange = (slotDay) => {
    this.setState({
      addModal: {
        ...this.state.addModal,
        slotDay,
      },
    });
  };

  newSlotStartTimeChange = (time, timeString) => {
    // console.log(time);
    // console.log(timeString);
    const stParts = timeString.split(':');
    // console.log(moment({hour: stParts[0], minute: stParts[1]}));
    // console.log(moment({hour: stParts[0], minute: stParts[1]}).add(this.state.addModal.duration, 'm').add(this.state.addModal.waitingTime, 'm').format('HH:mm'));
    let endtime = moment({ hour: stParts[0], minute: stParts[1] })
      .add(this.state.addModal.duration, 'm')
      .add(this.state.addModal.waitingTime, 'm')
      .format('HH:mm');
    // console.log(endtime);
    // console.log(this.state.addSlots);
    if (this.state.addSlots !== true) {
      this.setState({
        addModal: {
          ...this.state.addModal,
          endTime: endtime,
          startTimeObj: moment({ hour: stParts[0], minute: stParts[1] }),
        },
      });
    }
    this.setState({
      addModal: {
        ...this.state.addModal,
        startTime: timeString,
        endTime: endtime,
        startTimeObj: moment({ hour: stParts[0], minute: stParts[1] }),
      },
    });
  };

  newSlotEndTimeChange = (time, timeString) => {
    // console.log(time);
    // console.log(timeString);
    this.setState({
      addModal: {
        ...this.state.addModal,
        endTime: timeString,
      },
    });
  };

  newSlotApptTypeChange = (e) => {
    this.setState({
      addModal: {
        ...this.state.addModal,
        appttype: e.target.value,
      },
    });
  };

  newSlotWaitingTimeChange = (waitingtime) => {
    const stParts = this.state.addModal.startTime.split(':');
    let endtime = moment({ hour: stParts[0], minute: stParts[1] })
      .add(this.state.addModal.duration, 'm')
      .add(waitingtime, 'm')
      .format('HH:mm');
    // console.log(endtime);
    if (this.state.addSlots !== true) {
      this.setState({
        addModal: {
          ...this.state.addModal,
          endTime: endtime,
        },
      });
    }
    this.setState({
      addModal: {
        ...this.state.addModal,
        waitingTime: waitingtime,
      },
    });
  };

  newSlotDurationChange = (duration) => {
    this.setState({
      addModal: {
        ...this.state.addModal,
        duration,
      },
    });
  };

  range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  hoursRange = () => {
    let hoursListing = [];
    switch (this.state.addModal.slotPeriod) {
      case 'MS':
        hoursListing = this.range(0, 60).filter((i) => i < 6 || i > 9);
        break;
      case 'BLS':
        hoursListing = this.range(0, 60).filter((i) => i < 9 || i > 13);
        break;
      case 'ALS':
        hoursListing = this.range(0, 60).filter((i) => i < 13 || i > 17);
        break;
      case 'ES':
        hoursListing = this.range(0, 60).filter((i) => i < 17 || i > 22);
        break;
    }
    // console.log(hoursListing);
    return hoursListing;
  };

  handleClinicSelectionChange = (event) => {
    this.setState({
      physicianAssociationId: event.target.value,
    });
    this.getSlots(event.target.value);
  };

  render() {
    const { visible, loading } = this.state;
    return (
      <div className="slotManagementPage">
        <Modal
          visible={visible}
          title="Add new slot/slots"
          centered
          onOk={this.handleModalSubmit}
          onCancel={this.handleModalCancel}
          footer={[
            <Button key="back" onClick={this.handleModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={this.handleModalSubmit}
            >
              Submit
            </Button>,
          ]}
        >
          {this.state.addSlots && (
            <Row style={{ marginBottom: '10px' }}>
              <Title level={5} className={'addModalLabels'}>
                Slots for the day:&nbsp;&nbsp;
              </Title>
              <Select
                showSearch
                style={{ width: 100 }}
                placeholder="Select slot day"
                optionFilterProp="children"
                onChange={this.newSlotDayChange}
                // onFocus={onFocus}
                // onBlur={onBlur}
                // onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="Mon">Monday</Option>
                <Option value="Tue">Tuesday</Option>
                <Option value="Wed">Wednesday</Option>
                <Option value="Thu">Thursday</Option>
                <Option value="Fri">Friday</Option>
                <Option value="Sat">Saturday</Option>
                <Option value="Sun">Sunday</Option>
              </Select>
            </Row>
          )}
          <Row style={{ marginBottom: '10px' }}>
            <Title level={5} className={'addModalLabels'}>
              Slot start time:&nbsp;&nbsp;
            </Title>
            <TimePicker
              minuteStep={15}
              placeholder={'Start time'}
              defaultValue={moment(this.state.addModal.startTime, 'HH:mm')}
              format={'HH:mm'}
              disabledHours={this.hoursRange}
              hideDisabledOptions
              onChange={this.newSlotStartTimeChange}
            />
          </Row>
          <Row style={{ marginBottom: '10px' }}>
            <Title level={5} className={'addModalLabels'}>
              Slot end time:&nbsp;&nbsp;
            </Title>
            <TimePicker
              minuteStep={15}
              disabled={!this.state.addSlots}
              defaultValue={moment(this.state.addModal.endTime, 'HH:mm')}
              format={'HH:mm'}
              disabledHours={this.hoursRange}
              hideDisabledOptions
              onChange={this.newSlotEndTimeChange}
            />
          </Row>
          <Row style={{ marginBottom: '10px' }}>
            <Title level={5} className={'addModalLabels'}>
              Consultation period:&nbsp;&nbsp;
            </Title>
            <InputNumber
              className={'addModalInput'}
              min={5}
              defaultValue={this.state.addModal.duration}
              onChange={this.newSlotDurationChange}
            />
            <span className={'timescale'}>(mins)</span>
          </Row>
          <Row style={{ marginBottom: '10px' }}>
            <Title level={5} className={'addModalLabels'}>
              Waiting time:&nbsp;&nbsp;
            </Title>
            <InputNumber
              className={'addModalInput'}
              min={1}
              defaultValue={this.state.addModal.waitingTime}
              onChange={this.newSlotWaitingTimeChange}
            />
            <span className={'timescale'}>(mins)</span>
          </Row>
          <Row style={{ marginBottom: '10px' }}>
            <Title level={5} className={'addModalLabels'}>
              Consultation type:&nbsp;&nbsp;
            </Title>
            <Radio.Group
              onChange={this.newSlotApptTypeChange}
              defaultValue={'01'}
            >
              <Radio value={'01'}>Clinic visit</Radio>
              <Radio value={'04'}>Video consultation</Radio>
            </Radio.Group>
          </Row>
        </Modal>
        <div className="sectionnamePart fordesktopversion ">
          <NavLink to="/dashboard">
            <LeftOutlined className="arrow_back hidden-lg" />
            <span className="titlename"> Slot Management </span>
          </NavLink>
        </div>
        <div className={'detailsBox'}>
          <div className={'detailsLabel'}>
            Your approved clinics:
            <br />
            <Radio.Group
              value={this.state.physicianAssociationId}
              onChange={this.handleClinicSelectionChange}
            >
              {this.state.clinics.map((clinic) => (
                <Radio
                  key={'assocId-' + clinic.physicianAssociationId}
                  value={clinic.physicianAssociationId}
                >
                  {clinic.clinicName}
                </Radio>
              ))}
            </Radio.Group>
          </div>
          <div className={'detailsLabel'}>
            Do you want to associate with any other clinic?
            <br />
            <Radio.Group
              onChange={this.handleAssociateNewClinic}
              value={this.state.associateNewClinic}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </div>
          {this.state.associateNewClinic && (
            <div className={'newClinicBox'}>New clinic association.</div>
          )}
          <div className={'detailsLabel'}>
            Would like to view exception slot timings?
            <br />
            <Radio.Group
              onChange={this.handleSlotViewChange}
              value={this.state.exceptionSlotView}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </div>
          {this.state.exceptionSlotView ? (
            <div className={'exceptionDateSelection'}>
              <DatePicker onChange={this.handleSlotDateChange} />
            </div>
          ) : (
            <div className={'generalSlotTimings'}>
              <Tabs defaultActiveKey="1" type="card" size={'small'}>
                <TabPane tab="Morning" key="1">
                  <div className={'slotsDetailsContainer'}>
                    <Button
                      onClick={(event) => this.addNewSlot('', 'MS')}
                      type="primary"
                      style={{ marginBottom: 16, float: 'right' }}
                    >
                      Edit Slot Period
                    </Button>
                    <Table
                      bordered
                      dataSource={this.state.slotsMorningDataSource}
                      columns={this.state.tableColumns}
                    />
                  </div>
                </TabPane>
                <TabPane tab="Before Lunch" key="2">
                  <div className={'slotsDetailsContainer'}>
                    <Button
                      onClick={(event) => this.addNewSlot('', 'BLS')}
                      type="primary"
                      style={{ marginBottom: 16, float: 'right' }}
                    >
                      Edit Slot Period
                    </Button>
                    <Table
                      bordered
                      dataSource={this.state.slotsBeforeLunchDataSource}
                      columns={this.state.tableColumns}
                    />
                  </div>
                </TabPane>
                <TabPane tab="After Lunch" key="3">
                  <div className={'slotsDetailsContainer'}>
                    <Button
                      onClick={(event) => this.addNewSlot('', 'ALS')}
                      type="primary"
                      style={{ marginBottom: 16, float: 'right' }}
                    >
                      Edit Slot Period
                    </Button>
                    <Table
                      bordered
                      dataSource={this.state.slotsAfterLunchDataSource}
                      columns={this.state.tableColumns}
                    />
                  </div>
                </TabPane>
                <TabPane tab="Evening" key="4">
                  <div className={'slotsDetailsContainer'}>
                    <Button
                      onClick={(event) => this.addNewSlot('', 'ES')}
                      type="primary"
                      style={{ marginBottom: 16, float: 'right' }}
                    >
                      Edit Slot Period
                    </Button>
                    <Table
                      bordered
                      dataSource={this.state.slotsEveningDataSource}
                      columns={this.state.tableColumns}
                    />
                  </div>
                </TabPane>
              </Tabs>
            </div>
          )}
        </div>
        {/*<MaterialTable*/}
        {/*  options={{*/}
        {/*    search: false*/}
        {/*  }}*/}
        {/*  title=" "*/}
        {/*  columns={[*/}
        {/*    */}
        {/*    {title: 'Appointment Id', field: 'apptid'},*/}
        {/*    {title: 'Appointment Date', field: 'appdate'},*/}
        {/*    {title: 'Patient Name', field: 'patientname'},*/}
        {/*    {title: 'Service', field: 'service'},*/}
        {/*    {title: 'Appointment amount', field: 'tamount'},*/}
        {/*    {title: 'Payment status', field: 'status'}*/}
        {/*  ]}*/}
        {/*  data={this.state.physicianRevenueDetails}*/}
        {/*  components={{*/}
        {/*    Toolbar: props => (*/}
        {/*      <div className={'rangeSelection'}>*/}
        {/*        <p>Select the Date Range</p>*/}
        {/*        <RangePicker onChange={this.changeDateSelection} disabledDate={this.disabledDate} defaultValue={this.state.rangeValue}/>*/}
        {/*      </div>*/}
        {/*    ),*/}
        {/*  }}*/}
        {/*/>*/}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

function mapDispatchToProps(dispatch, state) {
  return {
    actions: bindActionCreators(actioncreators, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SlotManagement)
);
