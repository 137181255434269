import React, { Component } from 'react';
import './EditProfile.css';
//import 'antd/dist/antd.css';
import { LeftOutlined, UploadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
//import '@ant-design/compatible/assets/index.css';
import { Button, Input, message, Select, Upload } from 'antd';
import { NavLink, withRouter } from 'react-router-dom';
import * as actioncreators from '../../redux/action';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import personal from '../../images/personal.png';
import userPic from '../../images/User.png';
import contact from '../../images/contact.png';
import address from '../../images/home-address.png';
import openNotificationWithIcon from '../../Common/UiComponent/AlertComponent';

class EditProfileSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: {},
      showerror: false,
      uploading: false,
      fileList: [],
    };
    //console.log(this.props);
  }

  ///////// to show userdetails//////////////////

  componentDidMount() {
    //console.log(this.props);

    if (this.props.userdetails) {
      this.setState({
        bloodgroup: this.props.userdetails.bloodgroup,
        gender: this.props.userdetails.gender,
      });

      //console.log(this.props.userdetails);
      this.props.form.setFieldsValue({
        name: this.props.userdetails.name,
        email: this.props.userdetails.email,
        contactno: this.props.userdetails.contactno,
        address: this.props.userdetails.address,
        bloodgroup: this.props.userdetails.bloodgroup,
        gender: this.props.userdetails.gender,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // //console.log(nextProps.clinicRequest)
    if (!Object.is(nextProps.error, this.props.error)) {
      this.setState(
        {
          errorMessage: nextProps.error,
          showerror: true,
        }
        // , () => { //console.log(this.state.errorMessage) })
      );
    }
  }

  ////onchange function for gender////
  onChangeData = (e) => {
    //console.log(e);
    // e.preventDefault();
    if (this.props.userdetails) {
      this.setState(
        {
          gender: e,
        },
        () => {
          //console.log(this.state.gender, "....");
        }
      );
    }
  };

  //// onchange function for bloodgrp///
  bloodgroupData = (e) => {
    //console.log(e);
    // e.preventDefault();
    if (this.props.userdetails) {
      this.setState(
        {
          bloodgroup: e,
        },
        () => {
          //console.log(this.state.bloodgroup, "....");
        }
      );
    }
  };

  handleUpload = () => {
    const { fileList } = this.state;
    const userData = new FormData();
    // fileList.forEach(file => {
    //   userData.append('profilePicture', file);
    // });
    userData.append('profilePicture', fileList[0]);
    userData.append('userId', this.props.userdetails.id);

    const dataConfig = {
      method: 'POST',
      body: userData,
    };

    this.setState({
      uploading: true,
    });

    // You can use any AJAX library you like
    fetch(
      process.env.REACT_APP_apiurl + '/User/userprofilepictureupdate/',
      dataConfig
    )
      .then((result) => result.json())
      .then((result) => {
        if (result.success === 1) {
          this.setState({
            fileList: [],
            uploading: false,
          });
          message.success('Profile picture updated successfully.');
        } else {
          this.setState({
            fileList: [],
            uploading: false,
          });
          message.error(
            'Some error in updating profile picture, please try again after sometime.'
          );
        }
      });
  };

  /////// for API call Function//////////
  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        // if (this.props.userdetails) {
        //console.log(this.props.userdetails, "props");
        //console.log(values, "goes to edit in form");
        values.gender = this.state.gender;
        values.bloodgroup = this.state.bloodgroup;
        console.log(values);
        let formData = new FormData();
        formData.append('token', sessionStorage.getItem('token'));
        formData.append('uid', this.props.userdetails.id);
        formData.append('bloodgrp', values.bloodgroup);
        formData.append('name', values.name);
        formData.append('contactNo', values.contactno);
        formData.append('gender', values.gender);
        formData.append('address', values.address);

        for (var pair of formData.entries()) {
          //console.log(pair[0] + ', ' + pair[1]);
        }
        const config = {
          method: 'POST',
          body: formData,
        };
        fetch(process.env.REACT_APP_apiurl + '/User/update/', config)
          .then((result) =>
            //  //console.log(result))
            result.json()
          )
          .then((result) => {
            //console.log(result);
            if (result.success === 1) {
              //console.log(result);
              this.props.history.goBack();
              this.props.actions.userUpdate(result);
              openNotificationWithIcon(
                'success',
                'Profile updated successfully!!'
              );
            } else {
              openNotificationWithIcon('error', 'Update Profile Error!!');
            }
          })
          .catch((err) => {
            openNotificationWithIcon('error', 'Internal Server error!!');
          });
      } else {
        openNotificationWithIcon('error', 'Something went wrong!!');
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { Option } = Select;
    const { TextArea } = Input;
    const { uploading, fileList } = this.state;

    const fileProps = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        this.setState((state) => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };

    let profilePic = userPic;
    if (this.props.userdetails.profilePicture !== undefined) {
      profilePic = this.props.userdetails.profilePicture;
    }

    return (
      <div className="conferdr editprofile">
        <div className="sectionnamePart fordesktopversion">
          <NavLink to="/dashboard">
            <LeftOutlined className="arrow_back hidden-lg" />
            <span className="titlename">Edit Profile</span>
            {/* <i class="fas fa-ellipsis-h arrow_back ellipsis"></i> */}
          </NavLink>
        </div>

        {/* for destop version start */}
        {/* <div className="fordesktop1"> */}
        <div className="cardsection mycliniccard ">
          <div className={'profilePic'}>
            <p className="profiledetailsectiontitle heading">
              <img alt="" src={profilePic} className="persondetailimg" />
              Profile Picture
            </p>
            <div className="profiledetailsectiontextstart">
              <div className="feildrow">
                <label className={'profilePicLabel'} htmlFor={'profileUpload'}>
                  Profile image
                </label>
                <Upload {...fileProps}>
                  <Button icon={<UploadOutlined />}>Select File</Button>
                </Upload>
                <Button
                  type="primary"
                  onClick={this.handleUpload}
                  disabled={fileList.length === 0}
                  loading={uploading}
                  style={{ marginTop: 0, marginLeft: 10 }}
                >
                  {uploading ? 'Uploading' : 'Start Upload'}
                </Button>
              </div>
            </div>
          </div>

          <Form
            onSubmit={this.handleSubmit}
            className="login-form loginFormNew"
          >
            {this.state.showerror && (
              <p className="error"> {this.props.error}</p>
            )}

            <p className="profiledetailsectiontitle heading">
              <img alt="" src={personal} className="persondetailimg" />
              Personal Details
            </p>

            <div className="profiledetailsectiontextstart">
              {/* <p className="profilepara"><span className="profileparatitle">User Name : </span><span>dkshukla</span></p>
               <p className="profilepara"><span className="profileparatitle">Gender : </span><span>Female</span></p>
               <p className="profilepara"><span className="profileparatitle">Blood Group : </span><span>B+</span></p> */}
              <div className="feildrow">
                <Form.Item label="Physician Name" className="usernamediv">
                  {getFieldDecorator('name', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your Physician name!',
                      },
                    ],
                  })(<Input className="inputicon" autocomplete="off" />)}
                </Form.Item>
                <Form.Item label="Gender" className="usernamediv">
                  <Select
                    showSearch
                    style={{ width: 451 }}
                    placeholder="Select your gender"
                    optionFilterProp="children"
                    value={this.state.gender}
                    onChange={this.onChangeData}
                    // onFocus={onFocus}
                    // onBlur={onBlur}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option key={'gen-m'} value="M">
                      Male
                    </Option>
                    <Option key={'gen-f'} value="F">
                      Female
                    </Option>
                    <Option key={'gen-o'} value="O">
                      Others
                    </Option>
                  </Select>
                </Form.Item>
              </div>
              <div className="feildrow">
                <Form.Item label="Blood Group" className="usernamediv">
                  <Select
                    showSearch
                    style={{ width: 451 }}
                    placeholder="Select your blood group"
                    optionFilterProp="children"
                    onChange={this.bloodgroupData}
                    // onFocus={onFocus}
                    // onBlur={onBlur}
                    // onSearch={onSearch}
                    value={this.state.bloodgroup}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    className="bloodgrpselect"
                  >
                    <Option key={'bg-A+'} value="A+">
                      A+
                    </Option>
                    <Option key={'bg-B+'} value="B+">
                      B+
                    </Option>
                    <Option key={'bg-B-'} value="B-">
                      B-
                    </Option>
                    <Option key={'bg-AB+'} value="AB+">
                      AB+
                    </Option>
                    <Option key={'bg-AB-'} value="AB-">
                      AB-
                    </Option>
                    <Option key={'bg-O+'} value="O+">
                      O+
                    </Option>
                    <Option key={'bg-O-'} value="0-">
                      O-
                    </Option>
                  </Select>
                </Form.Item>
              </div>
            </div>
            <p className="profiledetailsectiontitle heading">
              <img alt="" src={contact} className="persondetailimg" />
              Contact Details
            </p>
            <div className="profiledetailsectiontextstart">
              {/* <p className="profilepara"><span className="profileparatitle">Contact Number : </span><span>123456789</span></p>
               <p className="profilepara"><span className="profileparatitle">Email : </span><span>abc@gmail.com</span></p> */}
              <div className="feildrow">
                <Form.Item label="Contact Number" className="usernamediv">
                  {getFieldDecorator('contactno', {
                    rules: [
                      { required: true, message: 'Please input your number!' },
                    ],
                  })(<Input className="inputicon" autocomplete="off" />)}
                </Form.Item>
                <Form.Item label="Email" className="usernamediv">
                  {getFieldDecorator('email', {
                    rules: [
                      { required: true, message: 'Please input your email!' },
                    ],
                  })(<Input className="inputicon" autocomplete="off" />)}
                </Form.Item>
              </div>
            </div>
            <p className="profiledetailsectiontitle heading">
              <img alt="" src={address} className="persondetailimg" />
              Address Details
            </p>
            <div className="profiledetailsectiontextstart">
              <div className="feildrow">
                <Form.Item label="Address" className="usernamediv">
                  {getFieldDecorator('address', {
                    rules: [
                      { required: true, message: 'Please input your address!' },
                    ],
                  })(
                    <TextArea
                      rows={4}
                      className="inputicon"
                      autocomplete="off"
                    />
                  )}
                </Form.Item>
              </div>
            </div>
            <Button
              type="primary"
              htmlType="submit"
              className="editprofile-save-button"
            >
              {'submit'}
            </Button>
          </Form>
        </div>
        {/* </div> */}
        {/* for desktop version end */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

function mapDispatchToProps(dispatch, state) {
  return {
    actions: bindActionCreators(actioncreators, dispatch),
  };
}

const WrappedLogin = Form.create({ name: 'horizontal_login' })(
  EditProfileSection
);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WrappedLogin)
);
