import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import './ConsultationPage.scss';
import { LeftOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
//import '@ant-design/compatible/assets/index.css';
import { Button, Card, Col, Input, message, Popconfirm, Row, Tabs } from 'antd';
// import { NavLink } from 'react-router-dom';
import * as actioncreators from '../../redux/action';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import deletebtn from '../../images/delete.png';
import videobtn from '../../images/videocall.png';
import videoactivebtn from '../../images/videocall-active.png';
import moment from 'moment';

const TabPane = Tabs.TabPane;
const Option = Col.Option;

function handleChange(value) {
  //console.log(`selected ${value}`);
}

var tempDate = new Date();
var cyear = tempDate.getFullYear();
var cmonth = '' + (tempDate.getMonth() + 1);
var cday = '' + tempDate.getDate();
if (cmonth.length < 2) {
  cmonth = '0' + cmonth;
}
if (cday.length < 2) {
  cday = '0' + cday;
}
var date = cyear + '-' + cmonth + '-' + cday;

// Dates using moment module so that we can compare them
let today_date = moment().format('YYYY-MM-DD');

class ConsultationPage extends Component {
  constructor() {
    super();
    this.state = {
      status: 'past',
      visible: false,
      showButtons: false,
    };
  }

  callback = (key) => {
    // this.setState({selectedkey:key})
    //console.log(key);

    switch (key) {
      case '1':
        this.props.actions.getPastAppointments();
        this.setState({ status: 'past' });

        break;
      case '2':
        // todayscallback
        this.props.actions.getTodaysAppointments();
        this.setState({ status: 'today' });
        break;
      case '3':
        //upcoming
        this.props.actions.getfutureappointments();
        this.setState({ status: 'future' });
        break;

      default:
      //console.log("To be selected");
    }
  };
  handleChangepastappointments = (x) => {
    //console.log(x);
    this.setState({
      pastappointments: x,
    });
  };
  handleChangetodaysappointments = (y) => {
    //console.log(y);
    this.setState({
      todaysappointments: y,
    });
  };
  handleChangeupcommig = (z) => {
    //console.log(z);
    this.setState({
      upcomming: z,
    });
  };

  UNSAFE_componentWillReceiveProps = (newProps) => {
    // console.log(newProps.pastappointments);
    //console.log(newProps.todaysappointments);
    //console.log(newProps.upcomming);
  };

  componentDidMount() {
    // console.log(this.props.location.state);
    // console.log(today_date);
  }

  //////// Adding Praqtibha //////////////////////////

  // cancel = (e) => {
  //   //console.log(e);
  //   message.error("Clicked on No");
  // }

  handleOk = (z) => {
    //console.log(z);
    this.setState({
      visible: false,
    });
    //console.log(z.id, "in cancel apptmnt");
    this.props.actions.cancelFutureAppointment(z.appid);
  };

  handleCancel = (e) => {
    //console.log(">>>>>>>>>>>>>>>>", e);
    this.setState({
      visible: false,
    });
  };

  redirect = (data) => {
    //console.log(data);

    let formData = new FormData();

    formData.append('appid', data.appid);

    for (var pair of formData.entries()) {
      //console.log(pair[0] + ", " + pair[1]);
    }
    const config = {
      method: 'POST',
      body: formData,
    };
    fetch(
      process.env.REACT_APP_apiurl + '/Appointments/getappointmentdetails/',
      config
    )
      .then((result) =>
        //  //console.log(result))
        result.json()
      )
      .then((result) => {
        //console.log(result);
        if (result.success === 1) {
          this.props.history.push({
            pathname: '/dashboard/appointmentdetails',
            from: 'consultation',
            data: result.result[0],
            status: this.state.status,
          });
        } else {
          message.error(
            'Details of appointment could not be fetched; please check your connection or contact admin'
          );
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  onclickedCancel = () => {
    //console.log("no button is clicked>>>>>>>>>>>>>>")
  };
  // cancelFutureAppointment = z => {
  //   //console.log(z);
  //   //console.log("yes button clicked>>>>>>>>>>>>>>>>")
  //   this.props.actions.cancelFutureAppointment(z);
  //   //console.log(id, "in cancel apptmnt");
  //   return (dispatch, getState )=> {
  //     let formData = new FormData();

  //     formData.append("cancelid", id);
  //     for (var pair of formData.entries()) {
  //       //console.log(pair[0] + ', ' + pair[1]);
  //     }
  //     const config = {
  //       method: 'POST',
  //       body: formData,
  //       header: [{
  //         "Content-Type": "application/json",
  //       }]
  //     }
  //     fetch(process.env.REACT_APP_apiurl + "/restapi/Appointments/cancelappointmentbydr/", config)
  //       .then(result =>
  //         //  //console.log(result))
  //         result.json())
  //       .then(result => {
  //         //console.log(result);
  //         //console.log(getState())
  //         if(result.success===1)
  //         {

  //         let futureApptList=[...getState().upcomming]
  //         //console.log(futureApptList);

  //         let index= futureApptList.findIndex(x=>{
  //           return x.appid=== id
  //         });
  //           this.props.actions.cancelFutureAppointment(id);
  //           this.setState(prevState => {
  //       let showButtons = { ...prevState.showButtons };
  //       showButtons[id] = "false";
  //       return { showButtons};
  //           }, () => //console.log(this.state.showButtons, ">>>>>>>>>115"))

  //         // })

  //         futureApptList[index].status="Cancelled"

  //         // dispatch(upcomming(futureApptList))
  //       }
  //       })
  //       .catch(err => {
  //         //console.log(err);
  //       });

  //   }
  // }

  generateCard = (x) => {
    let appDate = moment(x.appdate).format('YYYY-MM-DD');
    // let difference = today_date < appDate;
    // console.log(difference);
    let card = (
      <Col lg={8} key={'tabcard-' + x.id}>
        <Card
          bordered={true}
          style={{ width: 322 }}
          className="tabcard"
          onClick={() => {
            this.redirect(x);
          }}
        >
          <Row gutter={16}>
            <Col span={13}>
              <div>
                <p className="personnametext"> {x.patientname}</p>
                {/* Jyotishman Sahu */}
                <p className="bookdetailstext">Appt date : {x.appdate}</p>
                {/* Appt date : 2020-02-16 */}
                <p className="bookdetailstext">Booking slot: {x.timelist}</p>
                {/* Time:  6PM */}
                <p className="bookdetailstext">Mobile: {x.mobile}</p>
                {/*Mobile: 1234567890 */}
              </div>
              {/* {this.props.pastappointments.map(x => {
             return <p value={x.name}>{x.name}</p>;
             })} */}
            </Col>
            <Col span={6} />
            <Col span={4}>
              {/* {this.props.preData && this.props.preData === x.appid?
             
             <p className="bookedtext">Completed</p>
             */}
              <p className="bookedtext">{x.status}</p>
              {x.apptype == '01' && (
                <p className="video">
                  {today_date <= appDate && x.status != 'Cancelled' ? (
                    <Button
                      type="link"
                      onClick={(e) =>
                        this.openConsultWindow(e, x.connectionlink)
                      }
                    >
                      <img alt="" className="videoimg" src={videoactivebtn} />
                    </Button>
                  ) : (
                    <Button type="link">
                      <img alt="" className="videoimg" src={videobtn} />
                    </Button>
                  )}
                </p>
              )}
              {/* <span><Button className="deletebtn"><img alt="" src={deletebtn}/></Button></span> */}
            </Col>
          </Row>
        </Card>
      </Col>
    );
    return card;
  };

  openConsultWindow = (e, link) => {
    e.preventDefault();
    let windowFeatures =
      'location=yes,resizable=yes,scrollbars=yes,width=700,height=800,left=700,top=400';
    window.open(link, '_blank', windowFeatures);
  };

  searchAppointments = (e) => {
    // console.log(e.target.value);
    let searchText = e.target.value;
    if (searchText.length > 2) {
      this.props.actions.getCustomAppointments(searchText);
      this.setState({ status: 'custom' });
    }
  };

  render() {
    return (
      <div className="conferdr">
        <div className="sectionnamePart fordesktopversion">
          <NavLink to="/dashboard">
            <LeftOutlined className="arrow_back hidden-lg" />
            <span className="titlename">Consultations</span>
          </NavLink>
        </div>
        <div className="tabsectionPart consultations-area">
          <Tabs defaultActiveKey="2" type="card" onChange={this.callback}>
            {this.props.location &&
              this.props.location.state &&
              this.props.location.state.flag == false && (
                <TabPane tab="Past" key="1">
                  <div className="cardsection">
                    <Row gutter={16}>
                      {this.props.pastappointments.length === 0 ? (
                        <p className="nobooking">No appointments</p>
                      ) : (
                        this.props.pastappointments.map((x) => {
                          let appDate = moment(x.appdate).format('YYYY-MM-DD');
                          return (
                            <Col lg={8} key={'tabcard-' + x.id}>
                              <Card
                                bordered={true}
                                style={{ width: 322 }}
                                className="tabcard"
                                onClick={() => {
                                  this.redirect(x);
                                }}
                              >
                                <Row gutter={16}>
                                  <Col span={13}>
                                    <div>
                                      <p className="personnametext">
                                        {' '}
                                        {x.patientname}
                                      </p>
                                      {/* Jyotishman Sahu */}
                                      <p className="bookdetailstext">
                                        Appt date : {x.appdate}
                                      </p>
                                      {/* Appt date : 2020-02-16 */}
                                      <p className="bookdetailstext">
                                        Booking slot: {x.timelist}
                                      </p>
                                      {/* Time:  6PM */}
                                      <p className="bookdetailstext">
                                        Mobile: {x.mobile}
                                      </p>
                                      {/*Mobile: 1234567890 */}
                                    </div>
                                    {/* {this.props.pastappointments.map(x => {
                                   return <p value={x.name}>{x.name}</p>;
                                   })} */}
                                  </Col>
                                  <Col span={6} />
                                  <Col span={4}>
                                    {/* {this.props.preData && this.props.preData === x.appid?
                                   
                                   <p className="bookedtext">Completed</p>
                                   */}
                                    <p className="bookedtext">{x.status}</p>
                                    {x.apptype == '01' && (
                                      <p className="video">
                                        {today_date <= appDate &&
                                        x.status != 'Cancelled' ? (
                                          <Button
                                            type="link"
                                            onClick={(e) =>
                                              this.openConsultWindow(
                                                e,
                                                x.connectionlink
                                              )
                                            }
                                          >
                                            <img
                                              className="videoimg"
                                              src={videoactivebtn}
                                              alt={''}
                                            />
                                          </Button>
                                        ) : (
                                          <Button type="link">
                                            <img
                                              className="videoimg"
                                              src={videobtn}
                                              alt={''}
                                            />
                                          </Button>
                                        )}
                                      </p>
                                    )}
                                    {/* <span><Button className="deletebtn"><img alt="" src={deletebtn}/></Button></span> */}
                                  </Col>
                                </Row>
                              </Card>
                            </Col>
                          );
                        })
                      )}
                    </Row>
                  </div>
                </TabPane>
              )}

            <TabPane tab="Today" key="2">
              <div className="cardsection todaycards">
                <Row gutter={16} className="tabcards">
                  {this.props.todaysappointments.length === 0 ? (
                    <p className="nobooking">No appointments booked today</p>
                  ) : (
                    this.props.todaysappointments.map((y) => {
                      let appDate = moment(y.appdate).format('YYYY-MM-DD');
                      return (
                        <Col lg={8} key={'tabcard-' + y.id}>
                          <Card
                            bordered={true}
                            style={{ width: 322 }}
                            className="tabcard nobookingcard"
                            onClick={() => {
                              this.redirect(y);
                            }}
                          >
                            <Row gutter={16}>
                              <Col span={13}>
                                <div>
                                  <p className="personnametext">
                                    {y.patientname}
                                  </p>
                                  {/* Jyotishman Sahu */}
                                  <p className="bookdetailstext">
                                    Appt date : {y.appdate}
                                  </p>
                                  <p className="bookdetailstext">
                                    Booking slot : {y.timelist}
                                  </p>
                                  {/* Booking slot : {6PM} */}
                                  <p className="bookdetailstext">
                                    Mobile: {y.mobile}
                                  </p>
                                  {/*Mobile: 1234567890 */}
                                </div>
                              </Col>
                              <Col span={7} />
                              <Col span={4}>
                                <p className="bookedtext">{y.status}</p>

                                {y.apptype == '01' && (
                                  <p className="video">
                                    {today_date <= appDate &&
                                    y.status != 'Cancelled' ? (
                                      <Button
                                        type="link"
                                        onClick={(e) =>
                                          this.openConsultWindow(
                                            e,
                                            y.connectionlink
                                          )
                                        }
                                      >
                                        <img
                                          className="videoimg"
                                          src={videoactivebtn}
                                          alt={''}
                                        />
                                      </Button>
                                    ) : (
                                      <Button type="link">
                                        <img
                                          className="videoimg"
                                          src={videobtn}
                                          alt={''}
                                        />
                                      </Button>
                                    )}
                                  </p>
                                )}
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      );
                    })
                  )}
                </Row>
              </div>
            </TabPane>
            {this.props.location &&
              this.props.location.state &&
              this.props.location.state.flag == false && (
                <TabPane tab="Upcoming" key="3">
                  <div className="cardsection todaycards">
                    <Row gutter={16} className="tabcards">
                      {this.props.upcomming.length === 0 ? (
                        <p className="nobooking">No appointments booked yet</p>
                      ) : (
                        this.props.upcomming.map((z) => {
                          let appDate = moment(z.appdate).format('YYYY-MM-DD');
                          return (
                            <Col lg={8} key={'tabcard-' + z.id}>
                              <Card
                                bordered={true}
                                style={{ width: 322 }}
                                className="tabcard nobookingcard"
                              >
                                <Row gutter={16}>
                                  <Col span={13}>
                                    <p
                                      className="personnametext"
                                      onClick={() => {
                                        this.redirect(z);
                                      }}
                                    >
                                      {z.patientname}
                                    </p>
                                    {/* Jyotishman Sahu */}
                                    <p
                                      className="bookdetailstext"
                                      onClick={() => {
                                        this.redirect(z);
                                      }}
                                    >
                                      Appt date :{z.appdate}
                                    </p>
                                    <p
                                      className="bookdetailstext"
                                      onClick={() => {
                                        this.redirect(z);
                                      }}
                                    >
                                      Booking slot : {z.timelist}
                                    </p>
                                    {/* Booking slot :  {6PM} */}
                                    <p
                                      className="bookdetailstext"
                                      onClick={() => {
                                        this.redirect(z);
                                      }}
                                    >
                                      Mobile: {z.mobile}
                                    </p>
                                    {/*Mobile: 1234567890 */}
                                  </Col>
                                  <Col span={7} />
                                  <Col span={4}>
                                    <p className="bookedtext">{z.status}</p>
                                    {/* <div> */}
                                    {/* {this.state.showButtons[id] !== "false" && */}
                                    <span>
                                      <Popconfirm
                                        className="consultationpopup"
                                        title={
                                          'Are you sure you want to Cancel? '
                                        }
                                        onConfirm={() => this.handleOk(z)}
                                        onCancel={() => this.onclickedCancel()}
                                        okText="Yes"
                                        cancelText="No"
                                        placement="topRight"
                                      >
                                        {z.status !== 'Cancelled' && (
                                          <Button
                                            // onClick={() =>
                                            //   this.cancelFutureAppointment(z)
                                            // }

                                            title="Cancel"
                                            className="deletebtn"
                                          >
                                            <img
                                              src={deletebtn}
                                              alt={'delete'}
                                            />
                                          </Button>
                                        )}
                                      </Popconfirm>
                                    </span>
                                    {z.apptype == '01' && (
                                      <p className="video">
                                        {today_date <= appDate &&
                                        z.status != 'Cancelled' ? (
                                          <Button
                                            type="link"
                                            onClick={(e) =>
                                              this.openConsultWindow(
                                                e,
                                                z.connectionlink
                                              )
                                            }
                                          >
                                            <img
                                              className="videoimg"
                                              src={videoactivebtn}
                                              alt={''}
                                            />
                                          </Button>
                                        ) : (
                                          <Button type="link">
                                            <img
                                              className="videoimg"
                                              src={videobtn}
                                              alt={''}
                                            />
                                          </Button>
                                        )}
                                      </p>
                                    )}
                                    {/* }   */}
                                  </Col>
                                </Row>
                              </Card>
                            </Col>
                          );
                        })
                      )}
                    </Row>
                  </div>
                </TabPane>
              )}
            {this.props.location &&
              this.props.location.state &&
              this.props.location.state.flag == false && (
                <TabPane tab="Search" key="4">
                  <div className={'searchAppointments'}>
                    <Input
                      placeholder={'Search with patient name'}
                      className={'searchText'}
                      defaultValue={''}
                      onChange={this.searchAppointments}
                    />
                  </div>
                  <div className="cardsection todaycards">
                    <Row gutter={16} className="tabcards">
                      {this.props.customappointments.length === 0 ? (
                        <p className="nobooking">No appointments booked yet</p>
                      ) : (
                        this.props.customappointments.map((z) => {
                          return this.generateCard(z);
                        })
                      )}
                    </Row>
                  </div>
                </TabPane>
              )}
          </Tabs>
        </div>
      </div>
    );
  }
}

// export default ConsultationPage;
const mapStateToProps = (state) => {
  return state;
};

function mapDispatchToProps(dispatch, state) {
  return {
    actions: bindActionCreators(actioncreators, dispatch),
  };
}

const WrappedConsultation = Form.create({ name: 'horizontal_login' })(
  ConsultationPage
);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WrappedConsultation)
);
