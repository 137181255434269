import React, { Component } from 'react';
import './MyRevenues.scss';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { LeftOutlined } from '@ant-design/icons';
import { DatePicker, message } from 'antd';
import { NavLink, withRouter } from 'react-router-dom';
import * as actioncreators from '../../redux/action';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';

class MyRevenues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      physicianDetails: null,
      physicianRevenueDetails: [],
      fromDate: null,
      toDate: null,
      page: 0,
      rowsPerPage: 5,
    };
  }

  componentDidMount() {
    let fromDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
    let toDate = moment().format('YYYY-MM-DD');
    let rangeValue = [moment().subtract(7, 'days'), moment()];
    this.setState({
      fromDate,
      toDate,
      rangeValue: rangeValue,
    });

    let userData = new FormData();
    userData.append('userid', this.props.userdetails.id);
    const config = {
      method: 'POST',
      body: userData,
    };

    fetch(process.env.REACT_APP_apiurl + '/Physician/getphysicianid/', config)
      .then((result) => result.json())
      .then((result) => {
        if (result.success === 1) {
          let physicianDetails = result.result[0];
          this.setState({
            physicianDetails: physicianDetails,
          });
          let physicianData = new FormData();
          physicianData.append('drid', physicianDetails.id);
          physicianData.append('cond', "'" + fromDate + "' and '" + toDate + "'");

          const config = {
            method: 'POST',
            body: physicianData,
          };

          return fetch(process.env.REACT_APP_apiurl + '/Revenue/getdrrevenuebypid/', config);
        } else {
          message.error('Physician details could not be extracted.');
        }
      })
      .then((result) => result.json())
      .then((result) => {
        if (result.success === 1) {
          let physicianRevenues = result.result;
          let revenueDetails = [];
          physicianRevenues.forEach((item) => {
            item.tamount = item.tamount - item.disc_amount;
            revenueDetails.push(item);
          });
          this.setState({
            physicianRevenueDetails: revenueDetails,
          });
          
        }
        
      })
      .catch((err) => {
        console.error(err);
      });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (!Object.is(newProps.physicianRevenues, this.props.physicianRevenues)) {
      let revenueDetails = [];
      newProps.physicianRevenues.forEach((item) => {
        item.tamount = item.tamount - item.disc_amount;
        revenueDetails.push(item);
      });
      this.setState({ physicianRevenueDetails: revenueDetails });
    }
  }

  changeDateSelection = (date, dateString) => {
    if (this.state.physicianDetails) {
      let revenueCond = "'" + dateString[0] + "' and '" + dateString[1] + "'";
      this.setState({
        fromDate: dateString[0],
        toDate: dateString[1],
        rangeValue: date,
        page: 0,
      });
      this.props.actions.getPhysicianRevenueDetails(
        this.state.physicianDetails.id,
        revenueCond
      );
    }
  };

  disabledDate = (current) => {
    return current && current.valueOf() > Date.now();
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  render() {
    const { page, rowsPerPage } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, this.state.physicianRevenueDetails.length - page * rowsPerPage);

    return (
      <div className="leadtable">
        <div className="sectionnamePart fordesktopversion">
          <NavLink to="/dashboard">
            <LeftOutlined className="arrow_back hidden-lg" />
            <span className="titlename"> My Revenues </span>
          </NavLink>
        </div>

        <div className={'rangeSelection'}>
          <p>Select the Date Range</p>
          <DatePicker.RangePicker
            onChange={this.changeDateSelection}
            disabledDate={this.disabledDate}
            defaultValue={this.state.rangeValue}
          />
        </div>

        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Appointment Id</TableCell>
                <TableCell>Appointment Date</TableCell>
                <TableCell>Patient Name</TableCell>
                <TableCell>Service</TableCell>
                <TableCell>Appointment Amount</TableCell>
                <TableCell>Payment Status</TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              {this.state.physicianRevenueDetails
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row.apptid}>
                    <TableCell>{row.apptid}</TableCell>
                    <TableCell>{row.appdate}</TableCell>
                    <TableCell>{row.patientname}</TableCell>
                    <TableCell>{row.service}</TableCell>
                    <TableCell>{row.tamount}</TableCell>
                    <TableCell>{row.status}</TableCell>
                  </TableRow>
                ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20]}
                  colSpan={6}
                  count={this.state.physicianRevenueDetails.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                  ActionsComponent={(props) => (
                    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                      <IconButton
                        onClick={() => this.handleChangePage(event, 0)}
                        disabled={page === 0}
                        aria-label="first page"
                      >
                        <FirstPageIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => this.handleChangePage(event, page - 1)}
                        disabled={page === 0}
                        aria-label="previous page"
                      >
                        <KeyboardArrowLeft />
                      </IconButton>
                      <IconButton
                        onClick={() => this.handleChangePage(event, page + 1)}
                        disabled={
                          page >= Math.ceil(this.state.physicianRevenueDetails.length / rowsPerPage) - 1
                        }
                        aria-label="next page"
                      >
                        <KeyboardArrowRight />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          this.handleChangePage(
                            event,
                            Math.max(0, Math.ceil(this.state.physicianRevenueDetails.length / rowsPerPage) - 1)
                          )
                        }
                        disabled={
                          page >= Math.ceil(this.state.physicianRevenueDetails.length / rowsPerPage) - 1
                        }
                        aria-label="last page"
                      >
                        <LastPageIcon />
                      </IconButton>
                    </Box>
                  )}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

function mapDispatchToProps(dispatch, state) {
  return {
    actions: bindActionCreators(actioncreators, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyRevenues));
