import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import './profile.css';
import '../Dashboard/Dashboard.css';
import { LeftOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
//import '@ant-design/compatible/assets/index.css';
import { Avatar, Button, Tabs } from 'antd';
import * as actioncreators from '../../redux/action';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import personal from '../../images/personal.png';
import contact from '../../images/contact.png';
import address from '../../images/home-address.png';
import userPic from '../../images/User.png';

const TabPane = Tabs.TabPane;

class ProfileSection extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    //console.log(this.props.userdetails);
    this.setState({ userData: this.props.userdetails });
  }

  render() {
    let userProfilePicture = (
      <img src={userPic} alt={this.props.userdetails.name} />
    );
    if (this.props.userdetails.profilePicture !== undefined) {
      userProfilePicture = (
        <img
          src={this.props.userdetails.profilePicture}
          alt={this.props.userdetails.name}
        />
      );
    }
    return (
      <div className="conferdr">
        <div className="sectionnamePart fordesktopversion">
          <NavLink to="/dashboard">
            <LeftOutlined className="arrow_back hidden-lg" />
            <span className="titlename">My Profile</span>
            {/* <i class="fas fa-ellipsis-h arrow_back ellipsis"></i> */}
          </NavLink>
          <NavLink to="/dashboard/editprofilepage">
            <Button
              type="primary"
              htmlType="submit"
              className="editprofile-button"
            >
              Edit Profile
            </Button>
          </NavLink>
        </div>
        {/* <div className="tabsectionPart">
         <div className="formobileview">
         <div className="cardsection mycliniccard ">
         <div className="tabcards">
         <p>My Profile page</p>
         </div>
         </div>
         </div>
         </div> */}

        {/* for destop version start */}
        {/* <div className="fordesktop1"> */}
        <div className="cardsection mycliniccard ">
          <p className="profiledetailsectiontitle">
            <img alt="" src={userPic} className="persondetailimg" />
            Profile Picture
          </p>
          <div className="profiledetailsectiontextstart">
            <p className="profilepara">
              <span className="profileparatitle">Profile Picture : </span>
              <Avatar size={25} shape="circle" icon={userProfilePicture} />
            </p>
          </div>
          <p className="profiledetailsectiontitle">
            <img alt="" src={personal} className="persondetailimg" />
            Personal Details
          </p>
          <div className="profiledetailsectiontextstart">
            <p className="profilepara">
              <span className="profileparatitle">User Name : </span>
              <span>
                {this.props.userdetails ? this.props.userdetails.name : null}
              </span>
            </p>
            <p className="profilepara">
              <span className="profileparatitle">Gender : </span>
              <span>
                {this.props.userdetails ? this.props.userdetails.gender : null}
              </span>
            </p>
            <p className="profilepara">
              <span className="profileparatitle">Blood Group : </span>
              <span>
                {this.props.userdetails
                  ? this.props.userdetails.bloodgroup
                  : ''}
              </span>
            </p>
          </div>
          <p className="profiledetailsectiontitle">
            <img alt="" src={contact} className="persondetailimg" />
            Contact Details
          </p>
          <div className="profiledetailsectiontextstart">
            <p className="profilepara">
              <span className="profileparatitle">Contact Number : </span>
              <span>
                {this.props.userdetails
                  ? this.props.userdetails.contactno
                  : null}
              </span>
            </p>
            <p className="profilepara">
              <span className="profileparatitle">Email : </span>
              <span>
                {this.props.userdetails ? this.props.userdetails.email : null}
              </span>
            </p>
          </div>
          <p className="profiledetailsectiontitle">
            <img alt="" src={address} className="persondetailimg" />
            Address Details
          </p>
          <div className="profiledetailsectiontextstart">
            <p className="profilepara">
              <span className="profileparatitle">Address : </span>
              <span>
                {this.props.userdetails ? this.props.userdetails.address : null}
              </span>
            </p>
          </div>
        </div>
        {/* </div> */}
        {/* for desktop version end */}
      </div>
    );
  }
}

// export default MyClinicsPage;

const mapStateToProps = (state) => {
  return state;
};

function mapDispatchToProps(dispatch, state) {
  return {
    actions: bindActionCreators(actioncreators, dispatch),
  };
}

const Wrappedmyprofile = Form.create({ name: 'horizontal_login' })(
  ProfileSection
);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Wrappedmyprofile)
);
