import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './NoDataFound.css';
import background from '../../images/microscope.png';
//import '@ant-design/compatible/assets/index.css';

class NoDataFound extends Component {
  render() {
    return (
      <div className="background">
        <img alt="" src={background} className="searchimage" />
        <p className="oops">OOPS!!</p>
        <p className="font">No Data Found</p>
      </div>
    );
  }
}

export default withRouter(NoDataFound);
