import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import './AppointmentDetails.css';
import { LeftOutlined, UserOutlined } from '@ant-design/icons';
//import '@ant-design/compatible/assets/index.css';
import {
  Avatar,
  Badge,
  Button,
  Card,
  Col,
  message,
  Popover,
  Row,
  Select,
  Table,
  Tabs,
  Upload,
} from 'antd';
// import enhance from '../../images/enhance.jpg';
import confirmsign from '../../images/Appoitment-confirmed.png';
import dateimg from '../../images/Date.png';
import timeimg from '../../images/Time.png';
import * as actioncreators from '../../redux/action';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import openNotificationWithIcon from '../../Common/UiComponent/AlertComponent';
// import Moment from 'moment';
// const initialMoment = Moment();
const TabPane = Tabs.TabPane;
const Option = Select.Option;
// var cdate = new Date();
// var dd = String(cdate.getDate()).padStart(2, "0");
// var mm = String(cdate.getMonth() + 1).padStart(2, "0"); //January is 0!
// var yyyy = cdate.getFullYear();
var moment = require('moment');

function handleChange(value) {
  //console.log(`selected ${value}`);
}

function handleBlur() {
  //console.log("blur");
}

function handleFocus() {
  //console.log("focus");
}

class AppointmentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: '',
      path: '',
      cdate: '',
      uploaded: '',
      appointmentList: [],
      formFillData: {},
      showPDFbutton: false,
      fileList: [],
      pdfMedicationData: [],
      preStatus: '',
      // showPDFButtons: {}
      visible: false,
      patientHealthBoxVisible: false,
      patientVitals: {
        key: '1',
      },
      showVitalDetails: false,
      vitalsNoPagination: {
        top: 'none',
        bottom: 'none',
      },
      patientReportsList: null,
    };
  }

  handleClick = (e) => {
    //console.log(e);
    this.setState({
      showPDFbutton: this.props.preData.length > 0 ? true : false,
    });
  };

  hidePatientHealthBox = () => {
    this.setState({
      patientHealthBoxVisible: false,
    });
  };

  handlePatientHealthBoxVisibility = (flag) => {
    this.setState({
      patientHealthBoxVisible: flag,
    });
  };

  // handleCancel = e => {
  //   //console.log("79", e);
  //   this.setState({
  //     visible: false,
  //   });
  // };

  handleChangecategory = (value) => {
    //console.log(value);
    this.setState({
      category: value,
      reportChosen: true,
    });
  };

  componentDidMount() {
    if (
      this.props.location.data &&
      this.props.location.data.prescriptionstatus === 'Final'
    ) {
      this.setState({ showPDFbutton: true });
    } else {
      this.setState({ showPDFbutton: false });
    }
    if (!this.props.location.data) {
      //console.log("apptmntdtls.length===0");
      this.props.history.push('/dashboard');
    } else {
      this.getPatientVitals();
      this.getPatientReports();
      // Call to fetch the Physician details having specialization in service id format
      let phyFormData = new FormData();
      phyFormData.append('id', this.props.location.data.pid);
      const phyConfig = {
        method: 'POST',
        body: phyFormData,
      };
      fetch(
        process.env.REACT_APP_apiurl +
          '/Physician/getindividualphysiciandetail/',
        phyConfig
      )
        .then((response) => response.json())
        .then((response) => {
          // console.log(response);
          if (response.success === 1) {
            // console.log(response.result[0].specializations);
            this.props.location.data.specialization =
              response.result[0].specializations;
          } else {
            message.error(
              'Details of physician could not be fetched; please check your connection or contact admin'
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
      // }
    }
  }

  showHideVitalDetails = (event, flag) => {
    let textContent = flag ? 'Hide vitals' : 'View vitals';
    event.target.textContent = textContent;
    this.setState({
      showVitalDetails: flag,
    });
  };

  getPatientReports = () => {
    let patientFormData = new FormData();
    patientFormData.append('patientId', this.props.location.data.patientid);
    patientFormData.append('clinicId', this.props.location.data.clinic);
    const patientConfig = {
      method: 'POST',
      body: patientFormData,
    };
    fetch(
      process.env.REACT_APP_apiurl + '/Uploads/getuploadedfilebyparameter/',
      patientConfig
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.success === 1) {
          this.setState({
            patientReportsList: response.result,
          });
          this.props.location.data.patientReportsList = response.result;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getPatientVitals = () => {
    // Fetch and store the patient vitals information in state
    let patientFormData = new FormData();
    patientFormData.append('patientId', this.props.location.data.patientid);
    patientFormData.append('clinicid', this.props.location.data.clinic);
    const patientConfig = {
      method: 'POST',
      body: patientFormData,
    };
    fetch(
      process.env.REACT_APP_apiurl + '/Appointments/getpatientvitaldetails/',
      patientConfig
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.success === 1) {
          // console.log(response.result);
          let vitalsIds = response.result
            .map(function (vital) {
              return parseInt(vital.id);
            })
            .sort((a, b) => b - a);
          // console.log(vitalsIds);
          let latestVitals = response.result.find(
            (vital) => parseInt(vital.id) === vitalsIds[0]
          );
          // console.log(latestVitals);
          this.setState({
            patientVitals: {
              ...this.state.patientVitals,
              ...latestVitals,
            },
          });
          this.props.location.data.patientVitals = response.result[0];
        } else {
          // message.error (
          //   "Unable to fetch patient vitals."
          // );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  redirect = (e) => {
    this.props.history.push({
      pathname: '/dashboard/formfill',
      data: this.props.location.data,
    });
  };

  onChange = (e) => {
    if (this.state.category.length === 0) {
      alert('Please choose a cotegory first');
    } else {
      //console.log(e);
      if (e.file.status === 'error') {
        let formData = new FormData();
        formData.append('appid', this.props.location.data.appid);
        formData.append('category', this.state.category);
        formData.append('file', e.file.originFileObj);

        for (var pair of formData.entries()) {
          //console.log(pair[0], pair[1]);
        }
        const config = {
          method: 'POST',
          body: formData,
        };
        fetch(
          process.env.REACT_APP_apiurl + '/Uploads/fileuploadtostorage/',
          config
        )
          .then((result) => result.json())
          .then((result) => {
            //console.log(result);
            if (result.success === 1) {
              // message.success("File upload successfully");
              this.setState({
                path: result.Path,
              });
              this.setState({ fileList: e.file.originFileObj.name });
            } else {
              message.error('File upload failed');
            }
          })
          .catch((err) => {
            message.error('File upload failed', err);
            //console.log(err);
          });
      }
    }
  };
  submit = () => {
    let cdate = moment().format('MM/DD/YYYY');
    //   mm + "/" + dd + "/" + yyyy;
    //console.log(cdate);

    //console.log(this.state.category, this.state.path, cdate);
    if (this.state.reportChosen === true && this.state.path.length > 0) {
      //   this.props.actions.getfileuploadtSorage(
      //     this.state.category,
      //     this.state.path,
      //     cdate,
      //     this.props.location.data.appid,
      //     this.props.location.data.uhid,
      //     this.props.location.data.pid,
      //     this.props.location.data.clinicid
      //   );

      let formData = new FormData();

      formData.append('appid', this.props.location.data.appid);
      formData.append('uhid', this.props.location.data.uhid);
      formData.append('pid', this.props.location.data.pid);
      formData.append('cid', this.props.location.data.clinicid);
      formData.append('Path', this.state.path);
      formData.append('category', this.state.category);
      formData.append('cdate', cdate);
      for (var pair of formData.entries()) {
        //console.log(pair[0] + ', ' + pair[1]);
      }
      const config = {
        method: 'POST',
        body: formData,
      };
      fetch(process.env.REACT_APP_apiurl + '/Uploads/fileupload/', config)
        .then((result) =>
          //  //console.log(result))
          result.json()
        )
        .then((result) => {
          //console.log(result);
          //  dispatch(result)

          if (result.success === 1) {
            openNotificationWithIcon(
              'success',
              'Document uploaded successfully!!'
            );

            this.setState({
              fileList: [],
              path: '',
            });
          } else {
            openNotificationWithIcon(
              'error',
              'Document uploaded failed,please check connection or contact admin'
            );
          }
          // this.setState({fileList:[]})
        })
        .catch((err) => {
          //console.log(err);
        });
    } else {
      openNotificationWithIcon(
        'error',
        'Please select a category and a file to upload'
      );
    }
    // this.setState({

    // })
  };

  // Function to generate the patient health history content
  healthHistoryContent = () => {
    const columns = [
      {
        title: 'Height',
        dataIndex: 'height',
        key: 'height',
      },
      {
        title: 'Weight',
        dataIndex: 'weight',
        key: 'weight',
      },
      {
        title: 'BMI',
        dataIndex: 'bmi',
        key: 'bmi',
      },
      {
        title: 'Temperature',
        dataIndex: 'temp',
        key: 'temp',
      },
      {
        title: 'BPM',
        dataIndex: 'bpm',
        key: 'bpm',
      },
      {
        title: 'Oximeter SpO2',
        dataIndex: 'oxim',
        key: 'oxim',
      },
      {
        title: 'Respiratory Rate',
        dataIndex: 'res_rate',
        key: 'res_rate',
      },
      {
        title: 'BP (Sys BP)',
        dataIndex: 'systolicbp',
        key: 'systolicbp',
      },
      {
        title: 'BP (Dia BP)',
        dataIndex: 'diastolicbp',
        key: 'diastolicbp',
      },
    ];
    let patientVitalData = [this.state.patientVitals];
    // Generate reports list
    let reportsList = '';
    if (this.state.patientReportsList !== null) {
      reportsList = this.state.patientReportsList.map((report) => (
        <Row gutter={[8, 16]} key={'report-' + report.id}>
          <Col span={14}>{report.filename}</Col>
          <Col span={4}>{report.service_name}</Col>
          <Col style={{ textAlign: 'right' }} span={6}>
            <a
              href={report.filepath}
              target={'_blank'}
              className={'patient-report-link'}
              rel={'noreferrer'}
            >
              View report
            </a>
          </Col>
        </Row>
      ));
    }

    let healthHistoryHTML = (
      <div className={'patient-health-box'}>
        <Row gutter={[8, 16]} className={'patient-history-popover-box'}>
          <Col span={12}>
            <strong>Vitals</strong>
          </Col>
          <Col style={{ textAlign: 'right' }} span={12}>
            {this.state.patientVitals !== null ? (
              <Button
                className={'view-vitals-btn'}
                type={'link'}
                onClick={(event) =>
                  this.showHideVitalDetails(event, !this.state.showVitalDetails)
                }
              >
                View vitals
              </Button>
            ) : (
              <span
                className={'patient-health-data'}
                style={{ paddingLeft: '7px' }}
              >
                No vitals
              </span>
            )}
          </Col>
        </Row>
        <hr />
        <div className={this.state.showVitalDetails ? '' : 'hide'}>
          <Row gutter={[8, 16]}>
            <Col span={12}>
              Collection date:{' '}
              {this.state.patientVitals !== null
                ? this.state.patientVitals.updatedDt
                : ''}
            </Col>
            <Col style={{ textAlign: 'right' }} span={12}>
              <Button type={'primary'} onClick={this.getPatientVitals}>
                Refresh
              </Button>
            </Col>
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}>
              <Table
                dataSource={patientVitalData}
                columns={columns}
                pagination={{
                  position: [
                    this.state.vitalsNoPagination.top,
                    this.state.vitalsNoPagination.bottom,
                  ],
                }}
              />
            </Col>
          </Row>
          <hr />
        </div>
        <div className={'patient-reports-list-container'}>
          <Row gutter={[8, 16]}>
            <Col>
              <strong>Reports</strong>
            </Col>
          </Row>
          <hr />
          {reportsList}
        </div>
      </div>
    );
    return healthHistoryHTML;
  };

  healthHistoryHeader = () => {
    return (
      <Row gutter={[8, 16]}>
        <Col span={12}>Patient Health History</Col>
        <Col className={'patient-box-close'} span={12}>
          <a onClick={this.hidePatientHealthBox}>Close</a>
        </Col>
      </Row>
    );
  };

  render() {
    return (
      <div className="conferdr">
        <div className="sectionnamePart appointmentdetailsection fordesktopversion">
          {/* <div className="fordesktop"> */}
          <NavLink to="/dashboard">
            <LeftOutlined className="arrow_back hidden-lg" />
            <span className="titlename">Appointment Details</span>
          </NavLink>
          {/* </div> */}
          <div className="confirmation_detail">
            <img src={confirmsign} className="confirmsignimg" alt={'confirm'} />
            <p className="confirmmsg">Appointment Confirmed</p>
            <p className="confirmclinic">
              Clinic Name :{' '}
              {this.props.location.data && this.props.location.data.clinicname}
            </p>
          </div>
          <div className="middlecarddetail">
            <Card bordered={false} style={{ width: 325 }}>
              <Row gutter={16}>
                <Col span={4}>
                  <div className="cardprofileimg">
                    <div className="cardprofileleftprt">
                      <span>
                        <Badge dot>
                          <Avatar shape="circle" icon={<UserOutlined />} />
                        </Badge>
                      </span>
                    </div>
                  </div>
                </Col>
                <Col span={19}>
                  <p className="usernametext">
                    {this.props.location.data &&
                      this.props.location.data.patientname}
                  </p>
                  <p className="userid">
                    Id :{' '}
                    {this.props.location.data && this.props.location.data.appid}
                  </p>
                </Col>
              </Row>
            </Card>
          </div>
        </div>
        <div className="datetimecard">
          <Card bordered={true} style={{ width: 325 }}>
            <Row gutter={16}>
              <Col span={3}>
                <div className="cardprofileimg">
                  <div className="carddateiconleftprt">
                    <img alt="" src={dateimg} className="dateimg" />
                  </div>
                </div>
              </Col>
              <Col span={20}>
                <p className="datetimeinfo">
                  {this.props.location.data &&
                    this.props.location.data.dayslist +
                      '' +
                      this.props.location.data.appdate}
                </p>
              </Col>
            </Row>
          </Card>
          <Card bordered={true} style={{ width: 325 }}>
            <Row gutter={16}>
              <Col span={3}>
                <div className="cardprofileimg">
                  <div className="carddateiconleftprt">
                    <img alt="" src={timeimg} className="dateimg" />
                  </div>
                </div>
              </Col>
              <Col span={20}>
                <p className="datetimeinfo">
                  {this.props.location.data &&
                    this.props.location.data.timelist}
                </p>
              </Col>
            </Row>
          </Card>

          <div className="formbtnpres">
            {this.props.location && this.props.location.status !== 'future' && (
              <Button
                type="primary"
                className="eprescrptionbtn"
                onClick={this.redirect}
              >
                {' '}
                {'ePrescription'}
              </Button>
            )}

            {this.state.showPDFbutton === true && (
              <Button
                type={'primary'}
                className={'eprescrptionbtn'}
                href={this.props.location.data.pdfurl}
              >
                Download PDF
              </Button>
            )}

            <Popover
              content={this.healthHistoryContent}
              title={this.healthHistoryHeader}
              trigger="click"
              placement="bottom"
              visible={this.state.patientHealthBoxVisible}
              onVisibleChange={this.handlePatientHealthBoxVisibility}
            >
              <Button type="primary" className={'eprescrptionbtn'}>
                Health History
              </Button>
            </Popover>
          </div>
        </div>

        <div className="centeralignprob">
          <div className="selectcategory">
            <Select
              className=""
              placeholder="Select a Category"
              optionFilterProp="children"
              onChange={this.handleChangecategory}
              onFocus={handleFocus}
              onBlur={handleBlur}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="prescription">prescription</Option>
              <Option value="pathologyReport">pathology report</Option>
              <Option value="radiologyReport">radiology report</Option>
              <Option value="medicalCertificate"> medical certificate</Option>
            </Select>
          </div>
          <div className="fileupload-section">
            {this.state.category.length > 0 && (
              <Upload onChange={this.onChange} showUploadList={false}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button choosefilebtn"
                  style={{ marginTop: '0' }}
                >
                  Choose File
                </Button>
              </Upload>
            )}
            <div>
              <p className="nofiletext">
                {' '}
                {this.state.category.length > 0 ? (
                  this.state.fileList == '' ? (
                    'No File Choosen'
                  ) : (
                    <span>{this.state.fileList}</span>
                  )
                ) : (
                  'Choose a category to upload a file '
                )}
              </p>
              {/* </div> */}
            </div>
          </div>

          <div className="upload_btn">
            <Button
              type="primary"
              onClick={this.submit}
              className="login-form-button uploadbtn"
              style={{ marginTop: '0' }}
            >
              Upload Document
            </Button>
          </div>
          {/* <p className="nofiletext">
           {" "}
           {this.state.category.length > 0
           ? this.state.fileList.length === 0 ? "No File Choosen" : this.state.fileList.map(x => {
           return (
           <p>{x}</p>
           )
           })
           : "Choose a category to upload a file "}
           </p> */}
          {/* </div> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

function mapDispatchToProps(dispatch, state) {
  return {
    actions: bindActionCreators(actioncreators, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppointmentDetails)
);
